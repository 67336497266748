import React from 'react';

const Contact = () => {
    return (
        <main className="contact">
            <h1>Contact Us</h1>
            <h2><a className="contact-link" href="mailto:team@hofvarpnir.ai">team@hofvarpnir.ai</a></h2>
            <div className="individual-contacts">
                <div>
                    <h3>Nova DasSarma</h3>
                    <h4><a className='contact-link' href="https://novalinium.com/">Website</a></h4>
                </div>
                <div>
                    <h3>Shauna Kravec</h3>
                    <h4><a className='contact-link' href="https://celest.ai/">Website</a></h4>
                </div>
            </div>
        </main>
    )
}

export default Contact;
