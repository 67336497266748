import './App.scss';
import { useState } from 'react';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Partners from './pages/partners';

import { Routes, Route, Link } from "react-router-dom";

function App() {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navClass = dropdownOpen ? "open main" : "closed main";

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <h1><Link to="/"><img src="3nodes.png" className="hs-logo"/></Link></h1>
          <img 
            className="hamburger" 
            src="/hamburger.png" 
            alt="toggle dropdown menu" 
            onClick={toggleDropdown} 
          />
        </div>
        <nav className={navClass}>
          <Link to="/about" onClick={closeDropdown}>About</Link>
          <Link to="/partners" onClick={closeDropdown}>Our Partners</Link>
          <Link to="/contact" onClick={closeDropdown}>Contact Us</Link>
        </nav>
      </header>
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="partners" element={<Partners />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
