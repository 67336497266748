import ansibleLogo from './techlogos/ansible.svg';
import dockerLogo from './techlogos/docker.png';
import grafanaLogo from './techlogos/grafana.png';
import jaxLogo from './techlogos/jax.png';
import kubernetesLogo from './techlogos/kubernetes.png';
import prometheusLogo from './techlogos/prometheus.png';
import pytorchLogo from './techlogos/pytorch.png';
import tensorflowLogo from './techlogos/tensorflow.png';
import terraformLogo from './techlogos/terraform.svg';
import volcanoLogo from './techlogos/volcano.png';

const LOGO_TEMPLATE = {
    name: "Widget",
    url: "http://example.com/",
    logo: ansibleLogo,
}

const techLogos = [
    {
        name: "Docker",
        url: "https://www.docker.com/",
        logo: dockerLogo,
    },
    {
        name: "Grafana",
        url: "https://grafana.com/",
        logo: grafanaLogo,
    },
    {
        name: "Kubernetes",
        url: "https://kubernetes.io/",
        logo: kubernetesLogo,
    },
    {
        name: "Prometheus",
        url: "https://prometheus.io/",
        logo: prometheusLogo,
    },
    {
        name: "Pytorch",
        url: "https://pytorch.org/",
        logo: pytorchLogo,
    },
    {
        name: "TensorFlow",
        url: "https://www.tensorflow.org/",
        logo: tensorflowLogo,
    },
    {
        name: "Terraform",
        url: "https://www.terraform.io/",
        logo: terraformLogo,
    },
    {
        name: "Volcano",
        url: "https://volcano.sh/",
        logo: volcanoLogo,
    },
    {
        name: "Ansible",
        url: "https://www.ansible.com/",
        logo: ansibleLogo,
    },
    {
        name: "Jax",
        url: "https://github.com/google/jax",
        logo: jaxLogo,
    },
]

export default techLogos;