import React from 'react';

const partner = {
    name: "NAME",
    logo: "http://placekitten.com/200/200",
    description: `Here is a description of one of Hofvarpnir Studios' partners! 
    They use lots of compute for their exciting AI projects, and they probably
    won't blow up the world even a little bit. Probably there's going to be a
    description here of the general types of Computing Power they use to do their
    exciting AI projects. Probably also we will talk about what their project does.
    Maybe it invents anime girls or counterfeits currency.`,
}

const PARTNERS = [
{
    name: "Steinhardt Group",
    logo: "https://jsteinhardt.stat.berkeley.edu/images/profile.png",
    description: `Jacob Steinhardt is an Assistant Professor at UC Berkeley working on making machine learning systems reliable and aligned with human values. He and his group have researched a wide range of topics from out-of-distribution and adversarial robustness, characterizing reward hacking, to alignment of large language models. For more information, see the group's <a href="https://jsteinhardt.stat.berkeley.edu/" target="_blank">website</a> or his <a href="https://bounded-regret.ghost.io/" target="_blank">blog</a> for recent writing.`,
},    
{
    name: "Center for Human-Compatible AI",
    logo: "https://humancompatible.ai/app/themes/chai/dist/images/chai-logo_a729cbef.png",
    description: `The Center for Human-Compatible AI (CHAI) is a multi-institution research group based at UC Berkeley seeking to reorient the general thrust of AI research towards provably beneficial systems. CHAI conducts research in a range of areas, but has a particular speciality in reward learning and multi-agent systems. For more information, see CHAI's <a href="https://humancompatible.ai/" target="_blank">website</a> and <a href="https://humancompatible.ai/research" target="_blank">publications</a>.`,
}
];

const FUNDERS = [
{
    name: "Open Philanthropy",
    logo: "https://www.openphilanthropy.org/sites/default/files/Open_Primary_RGB.jpg",
    description: `Open Philanthropy identifies outstanding giving opportunities, makes grants, follows the results, and publishes its findings. Its mission is to give as effectively as it can and share the findings openly so that anyone can build on them.`,
},
/* {
    name: "BERI",
    logo: "https://www.openphilanthropy.org/sites/default/files/styles/medium/public/grants/BERI%20logo%20OP.jpg",
    description: `BERI is an independent public charity. Our mission is to improve human civilization’s long-term prospects for survival and flourishing. Currently, our main strategy is collaborating with university research groups working to reduce existential risk (“x-risk”), by providing them with free services and support.`,
}, */
{
    name: "Effective Altruism Infrastructure Fund",
    logo: "https://app.effectivealtruism.org/logo-funds.svg",
    description: `The Effective Altruism Infrastructure Fund aims to increase the impact of projects that use the principles of effective altruism, by increasing their access to talent, capital, and knowledge.`,
}
];

const PartnerRow = (props) => {
    return (<div className="partner-row">
        <div className="logo-frame">
            <img src={props.logoUrl}/>
        </div>
        <h3>{props.name}</h3>
        <p dangerouslySetInnerHTML={{__html: props.description}} />
    </div>)
}

const Partners = () => {
    return (
        <main className="partners">
            <h1>Our Partners</h1>
            { PARTNERS.map((partner, idx) => {
                return (<PartnerRow 
                    name={partner.name} 
                    description={partner.description} key={idx} 
                    logoUrl={partner.logo}
                />)
            })}
            <h1>Our Funders</h1>
            { FUNDERS.map((partner, idx) => {
                return (<PartnerRow
                    name={partner.name}
                    description={partner.description} key={idx}
                    logoUrl={partner.logo}
                />)
            })}
        </main>
    )
}


export default Partners;
