import React from 'react';
import techLogos from './techLogos';


const Home = () => {
    return (
        <main className="home">
            <div className="splash-slogan">
                <span className="slogan">Building compute for AI safety research.</span>
                <span className="slogan-caption">Satisfying values through consulting and infrastructure.</span>
            </div>
            <div className="we-believe solid-block">
                <p><span className="title-text">We believe </span>that artificial 
                intelligence has the potential to be a transformative technology 
                in the 21st century. It is therefore an urgent priority to develop 
                such systems in a way that's aligned to human preferences and behaves 
                in expected ways.</p>
                <p><img src="https://miro.medium.com/max/1200/1*LhbXZtQfoQHAXP2D_NghTg.png" className="hide-on-mobile" />
                    <b>As neural networks become larger and more complex, </b>
                    the task of studying these systems becomes a much more computationally 
                    intensive endeavor. It&apos;s our mission to bridge the gap between safety academics 
                    and high performance computing, getting them the resources they need to 
                address these challenging problems.</p>
                <p><b>We're partnering with safety labs </b>
                to build a GPU cluster designed to handle a
                wide variety of machine learning jobs from fine-tuning large
                natural language models such as GPT-3, to CPU intensive
                reinforcement learning workloads. We&apos;re also building a smaller
                scale cluster available to independent AI safety researchers.
                We&apos;ve worked with researchers at UT Austin, MILA, UC Berkeley,
                    Redwood Research, and more to provide infrastructure support.</p>
            </div>
            <div className="technologies open-block">
                <h2>Our Technologies</h2>
                <div className="logos">
                    {techLogos.map((item => {
                       return <a href={item.url}><img className="tech-logo" src={item.logo} alt={item.name}/></a>
                    }))}
                </div>
            </div>
        </main>
    )
}

export default Home;
