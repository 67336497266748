import React from 'react';

const About = () => {
    return (
        <main className="about">
            <h1>About Us</h1>
            <div className="bios">
                <div className="bio">
                    <div className="portrait-frame">
                        <img className="portrait-large" src="nova.jpg" />
                    </div>
                    <h3>Nova DasSarma</h3>
                    <p>Nova serves as financial director. Her background includes 
                        systems administration at the NIH, several YC startups, 
                        and she now leads up infrastructure as a Principal Systems 
                        Architect at Anthropic.
                    </p>
                </div>
                <div className="bio">
                    <div className="portrait-frame">
                        <img className="portrait-large" src="shauna.jpg" />
                    </div>
                    <h3>Shauna Kravec</h3>
                    <p>Shauna serves as executive director. She is an AI safety researcher at Anthropic, where 
                        she focuses on reinforcement learning and large language models. 
                        Her background is in theoretical physics in which she has a PhD 
                        from UC: San Diego. 
                    </p>
                </div>
            </div>
        </main>
    )
}

export default About;
